<!-- 派单模块配置 -->
<template>
  <el-dialog
      width="60%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'派单模块配置':'派单模块配置'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="120px">

      <el-row>
        <el-col :span="12">
          <el-form-item label="派单模式：">
            <el-select
                clearable
                v-model="form.send_pattern"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                  v-for="(item) in dict.type.sendorders_pattern"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="danxuan">
            <el-form-item label="是否可以拒单：">
              <el-radio-group v-model="form.is_refuse">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <div style="font-size: 12px;font-weight: 400;color: #9a9cb4;padding-left: 15px;">如果设置为否,则在司机端订单列表页面不显示拒单按钮</div>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="拖车免拖里程：">
            <el-input
                placeholder="请输入"
                v-model="form.free_mileage"
                clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="接单模式：">
            <el-select
                clearable
                v-model="form.join_pattern"
                placeholder="请选择"
                class="ele-fluid">
              <el-option
                  v-for="(item) in dict.type.orderreceiving_pattern"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="form.join_pattern == 'api' && is_vip == false">
        <el-col :span="24">
          <span style="font-size: 16px;font-weight: 400;color: #3f4157;margin-right: 50px;">开通API接口自动接单需要升级为ⅥP会员</span>
          <el-button style="background: #FF9B05!important;border-color: #FF9B05!important;color: #FFFFFF!important;">去升级</el-button>
        </el-col>
      </el-row>

      <el-row style="margin-top: 15px;" v-if="form.join_pattern == 'api' && is_vip == false">
        <el-col :span="24">
          <span style="font-size: 16px;font-weight: 400;color: #3f4157;margin-right: 50px;">或者单独支付XXX元开通服务</span>
          <el-button style="background: #53D1A1!important;border-color: #53D1A1!important;color: #FFFFFF!important;" @click="toPublicPage()">去升级</el-button>
        </el-col>
      </el-row>

      <el-row style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 6px;height: 20px;background: #a3a4b2;display: inline-block;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4157;margin-left: 10px;">派单注意事项</span>
        </div>
        <div style="margin-top: 10px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入派单注意事项"
              v-model="form.attention">
          </el-input>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;margin-bottom: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 6px;height: 20px;background: #a3a4b2;display: inline-block;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4157;margin-left: 10px;">接单服务说明</span>
        </div>
        <div style="margin-top: 10px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入接单服务说明"
              v-model="form.explain">
          </el-input>
        </div>
      </el-row>

    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import {get_vip, Savecreate_dispatch_config} from "@/api/custom";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object,
    // 客户id
    id:Number,
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 是否是会员
      is_vip:false,

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data);
      this.form = this.data;
      console.log(this.form);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getVip();
  },


  methods: {
    //获取是否为会员接口
    getVip(){
      get_vip().then(res => {
        this.is_vip = res.data.status;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.channel_id =  this.id;
          Savecreate_dispatch_config(this.form).then(res => {
            if(res.code == 200) {
              this.$message.success(res.msg)
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done1');
              this.loading = false;
            }else {
              this.$message.error(e.msg)
            }
          }).catch(e => {
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


    // 开通服务
    toPublicPage(){
      this.$emit('update:visible', value);
      this.$router.push('/PublicPage/ActivateService')
    },


  }
}
</script>

<style scoped lang="scss">
.kuai{
  .kuai_l{
    float: left;
    margin-top: 5px;
  }
  .kuai_r{
    float: left;
  }
}
.kuai:after{
  content: '';
  clear: both;
  display: block;
}

.bg{
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e3e4e9;
  border-radius: 6px;
}
</style>
